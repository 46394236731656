import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, message, Modal, Space, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import {
  deletePubService,
  getAllPubService
} from 'common/services/extends/pub';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

export type PubDataTypes = {
  id: number;
  name: string;
  address: string;
  coordinatesLat: number,
  coordinatesLong: number,
  provinceName: string,
  provinceCode: string,
  createdAt: string,
  updatedAt: string,
};

const PubManagement: React.FC<ActiveRoles> = ({
  roleCreate, roleDelete, roleUpdate
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Selectors */
  const {
    defaultPageSize, advancedFilter
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* Queries */
  const { isLoading, data: pubDataList } = useQuery(
    ['getAllPubService', keyword, currentPage, currentView, selectedFilterList],
    () => getAllPubService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    { enabled: !!currentPage }
  );

  const { mutate: deletePubMutate, isLoading: deletePubLoading } = useMutation(
    ['pub-delete'],
    async (ids: number[]) => deletePubService({ ids }),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(['getAllPubService']);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  const onDelete = async (data: PubDataTypes[]) => {
    if (data && data.length > 0) {
      deletePubMutate(data.map((item) => item.id));
    }
  };

  /* Variables */
  const columns: ColumnsType<PubDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: PubDataTypes, b: PubDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: PubDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: PubDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.name}
        </Typography.Text>
      ),
    },
    {
      title: t('footer.address'),
      dataIndex: 'address',
      key: 'address',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: PubDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.address}
        </Typography.Text>
      ),
    },
    {
      title: t('pub.coordinatesLat'),
      dataIndex: 'coordinatesLat',
      key: 'coordinatesLat',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: PubDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.coordinatesLat}
        </Typography.Text>
      ),
    },
    {
      title: t('pub.coordinatesLong'),
      dataIndex: 'coordinatesLong',
      key: 'coordinatesLong',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: PubDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.coordinatesLong}
        </Typography.Text>
      ),
    },
    {
      title: t('pub.provinceName'),
      dataIndex: 'provinceName',
      key: 'provinceName',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: PubDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.provinceName}
        </Typography.Text>
      ),
    },
    {
      title: t('pub.provinceCode'),
      dataIndex: 'provinceCode',
      key: 'provinceCode',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: PubDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.provinceCode}
        </Typography.Text>
      ),
    },
    // --- Tạo lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 250,
      sorter: {
        compare: (a: PubDataTypes, b: PubDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: PubDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 250,
      key: 'updatedAt',
      sorter: {
        compare: (a: PubDataTypes, b: PubDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: PubDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: PubDataTypes) => (
        <Space>
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.PUB_DETAIL}?id=${_data.id}`)}
          />
          <Button
            disabled={!roleDelete}
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                className: 't-pagetable_deleteRecordModal',
                autoFocusButton: 'cancel',
                title: t('message.confirmDeleteRecord'),
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                onOk: () => {
                  onDelete([_data]);
                },
              });
            }}
          />
        </Space>
      ),
    },
  ];

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('pubs', advancedFilter),
    [advancedFilter]
  );

  const pubData: PubDataTypes[] = useMemo(() => (pubDataList?.data?.map(
    (val) => ({
      id: val.pubData.id,
      name: val.pubData?.name || '',
      coordinatesLat: val.pubData?.coordinates?.lat || 0,
      coordinatesLong: val.pubData?.coordinates?.long || 0,
      address: val.pubData.address || '',
      provinceName: val.province?.name || '',
      provinceCode: val.province?.code || '',
      createdAt: val.pubData?.createdAt || '',
      updatedAt: val.pubData?.updatedAt || '',
    })
  ) || []), [pubDataList]);

  /* Functions */
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.pub')}
        rightHeader={(
          <Button
            type="primary"
            disabled={!roleCreate}
            onClick={() => navigate(ROUTE_PATHS.PUB_DETAIL)}
          >
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading || deletePubLoading}
          handleSearch={setKeyword}
          handleDelete={onDelete}
          handleEditPage={(id) => {
            navigate(`${ROUTE_PATHS.PUB_DETAIL}?id=${id}`);
          }}
          handleCreatePage={(id) => {
            navigate(`${ROUTE_PATHS.PUB_DETAIL}?id=${id}`);
          }}
          roles={{ roleCreate, roleDelete, roleUpdate }}
          tableProps={{
            initShowColumns: ['id', 'name', 'address', 'coordinatesLat', 'coordinatesLong', 'provinceName', 'provinceCode', 'createdAt', 'updatedAt', 'action'],
            columns,
            pageData: pubData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: pubDataList?.meta.page || 1,
            filterFields,
            noBaseCol: true
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
        />
      </div>
    </>
  );
};

export default PubManagement;
