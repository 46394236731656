import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { getAccessToken } from 'common/services/common/storage';
import { ROUTE_PATHS } from 'common/utils/constant';

const GenerateQRCode: React.FC = () => {
  const rolesUser = useAppSelector((state) => state.auth.roles);
  const websiteDomain = useAppSelector((state) => state.system.initialData?.websiteDomain);
  const token = getAccessToken();
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id') || '';
  const valueLogo = searchParams.get('logo') || '';
  const widthLogo = searchParams.get('width') || 50;
  const heightLogo = searchParams.get('height') || 50;

  if (!token) {
    return <Navigate to="/login" state={{ from: ROUTE_PATHS.GENERATE_QRCODE }} replace />;
  }

  if (!rolesUser.includes('*')) {
    return <Navigate to="/404" replace />;
  }

  /* Render */
  return (
    <div className="p-generateQRCode">
      <QRCodeSVG
        size={400}
        value={`${websiteDomain || ''}?id=${id}`}
        imageSettings={valueLogo ? {
          src: valueLogo,
          x: undefined,
          y: undefined,
          height: Number(heightLogo),
          width: Number(widthLogo),
          excavate: true,
        } : undefined}

      />
    </div>
  );
};

export default GenerateQRCode;
