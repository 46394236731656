import {
  Card, Col,
  Row, Space, Spin, Typography
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useQuery
} from 'react-query';
import { useSearchParams } from 'react-router-dom';

import CustomerCheckInManagement from './checkin';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import { getCustomerByIdService } from 'common/services/extends/customer';
import roles, { getPermission } from 'configs/roles';

export type CustomerDetailFormTypes = {
  name: string;
  address: string;
  provinceId: number;
  lat: number;
  long: number;
};

const CustomerDetail: React.FC<ActiveRoles> = (props) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const rolesUser = useAppSelector((state) => state.auth.roles);

  /* States */
  const idParams = Number(searchParams.get('id'));

  /* Queries */
  const { data: dataDetail, isLoading } = useQuery(
    ['getCustomerById', idParams],
    () => {
      if (idParams) {
        return getCustomerByIdService({
          id: idParams,
        });
      }
      return undefined;
    },
    {
      enabled: !!idParams,
      refetchOnMount: 'always',
    }
  );

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('customer.detail')}
      />
      <div className="t-mainlayout_wrapper">
        <Spin size="large" spinning={isLoading}>
          <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <Card type="inner">
              <Row gutter={16}>
                <Col span={12}>
                  <div className="p-editPageTemplate_input">
                    <Typography.Text strong>
                      {t('system.name')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>{dataDetail?.customerData.name}</Typography.Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="p-editPageTemplate_input">
                    <Typography.Text strong>
                      {t('system.phone')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>{dataDetail?.customerData.phone}</Typography.Text>
                  </div>
                </Col>
              </Row>
              <Row gutter={16} className="u-mt-16">
                <Col span={12}>
                  <div className="p-editPageTemplate_input">
                    <Typography.Text strong>
                      {t('system.dob')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>{dataDetail?.customerData.dob}</Typography.Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="p-editPageTemplate_input">
                    <Typography.Text strong>
                      {t('customer.receiverPhone')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>{dataDetail?.customerData.receiverPhone}</Typography.Text>
                  </div>
                </Col>
              </Row>
              <Row gutter={16} className="u-mt-16">
                <Col span={12}>
                  <div className="p-editPageTemplate_input">
                    <Typography.Text strong>
                      {t('customer.receiverName')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>{dataDetail?.customerData.receiverName}</Typography.Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="p-editPageTemplate_input">
                    <Typography.Text strong>
                      {t('customer.receiverAddress')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>{dataDetail?.customerData.receiverAddress}</Typography.Text>
                  </div>
                </Col>
              </Row>
            </Card>
            {
              getPermission(rolesUser, roles.CUSTOMER_CHECIN) && (
                <Card type="inner">
                  <CustomerCheckInManagement
                    {...props}
                  />
                </Card>
              )
            }
          </Space>
        </Spin>
      </div>
    </>
  );
};

export default CustomerDetail;
