import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, message, Row, Space, Spin, Typography
} from 'antd';
import React, {
  useEffect, useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useMutation, useQuery, useQueryClient
} from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ModalConfirm from 'common/components/ModalConfirm';
import SelectFile from 'common/components/SelectFile';
import { createPubService, getPubByIdService, updatePubByIdService } from 'common/services/extends/pub';
import { CreateUpdatePubParamsType } from 'common/services/extends/pub/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { createUpdatePubSchema } from 'common/utils/schemas';

export type EditPubFormTypes = {
  name: string;
  address: string;
  provinceId: number;
  lat: number;
  long: number;
  pathImage?: string;
  altImage?: string;
  titleImage?: string;
};

const EditPub: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  /* States */
  const idParams = Number(searchParams.get('id'));
  const [confirm, setConfirm] = useState<string | undefined>(undefined);

  /* Variables */
  const defaultValues = {
    name: '',
    address: '',
    provinceId: undefined,
    lat: undefined,
    long: undefined,
  };

  const method = useForm<EditPubFormTypes>({
    resolver: yupResolver(createUpdatePubSchema),
    defaultValues
  });

  /* Queries */
  const { data: pubByIdData, isLoading } = useQuery(
    ['getPubById', idParams],
    () => {
      if (idParams) {
        return getPubByIdService({
          id: idParams,
        });
      }
      return undefined;
    },
    {
      enabled: !!idParams,
      refetchOnMount: 'always',
    }
  );

  const { mutate: updatePubByIdMutate, isLoading: updateLoading } = useMutation(
    'updatePubById',
    async (data: {
      id: number;
      params: CreateUpdatePubParamsType
    }) => updatePubByIdService(data.id, data.params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['getPubById', idParams]);
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const { mutate: createPubMutate, isLoading: createLoading } = useMutation(
    'createPub',
    createPubService,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigate(`${ROUTE_PATHS.PUB_MANAGEMENT}`);
      },
      onError: () => {
        message.error(t('message.createError'));
      }
    }
  );

  const onSubmit = async (addingFn?: (id: number) => void) => {
    const isValid = await method.trigger();
    if (!isValid) {
      return;
    }

    const formData = method.getValues();

    const pubParams: CreateUpdatePubParamsType = {
      name: formData.name,
      provinceId: formData.provinceId,
      lat: formData.lat,
      long: formData.long,
      ...((formData.pathImage || formData.altImage || formData.titleImage) && {
        image: {
          path: formData.pathImage,
          alt: formData.altImage,
          title: formData.titleImage,
        }
      }),
      ...(formData.address && { address: formData.address })
    };

    if (idParams) {
      updatePubByIdMutate({
        id: idParams,
        params: pubParams
      });
    } else {
      createPubMutate(pubParams, {
        onSuccess: (data) => addingFn && addingFn(data.id),
      });
    }
  };

  const submitForm = async () => {
    await onSubmit();
    queryClient.invalidateQueries(['getPubById', idParams]);
  };

  /* Effects */
  useEffect(() => {
    if (pubByIdData) {
      if (pubByIdData) {
        const objDefault = {
          name: pubByIdData.pubData.name,
          address: pubByIdData.pubData.address,
          provinceId: pubByIdData.province?.id,
          lat: pubByIdData.pubData.coordinates?.lat,
          long: pubByIdData.pubData.coordinates?.long,
          pathImage: pubByIdData.pubData.image?.path,
          altImage: pubByIdData.pubData.image?.alt,
          titleImage: pubByIdData.pubData.image?.title,
        };
        method.reset(objDefault);
      }
    } else method.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pubByIdData]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.editPub')}
        rightHeader={(
          <Space size={16}>
            <Button
              type="primary"
              disabled={!pubByIdData?.pubData.id}
              onClick={() => window.open(`${window.location.origin + ROUTE_PATHS.GENERATE_QRCODE}?id=${pubByIdData?.pubData.id}`, '_blank')}
            >
              {t('pub.generateQRButton')}
            </Button>
            <Button
              type="primary"
              disabled={(idParams && !roleUpdate) || (!idParams && !roleCreate)}
              loading={updateLoading || createLoading}
              onClick={method.handleSubmit(submitForm)}
            >
              <SaveOutlined />
              {t('system.save')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Spin size="large" spinning={isLoading || updateLoading || createLoading}>
          <FormProvider {...method}>
            <Space direction="vertical" size={12} style={{ width: '100%' }}>
              <Card type="inner">
                <Row gutter={16}>
                  <Col span={12}>
                    <div className="p-editPageTemplate_input">
                      <Typography.Text strong>
                        {t('system.name')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="name"
                        defaultValue=""
                        control={method.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="name"
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="p-editPageTemplate_input">
                      <Typography.Text strong>
                        {t('pub.provinceName')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="provinceId"
                        defaultValue={undefined}
                        control={method.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <DropdownElement
                              type="province"
                              placeholder={`${t('system.select')} ${t('pub.provinceName')}`}
                              locale="vi"
                              value={value}
                              onChange={onChange}
                              isShowSearch
                              size="large"
                            />
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {error.message}
                              </span>
                            )}

                          </>
                        )}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={16} className="u-mt-16">
                  <Col span={12}>
                    <div className="p-editPageTemplate_input">
                      <Typography.Text strong>
                        {t('pub.coordinatesLat')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="lat"
                        defaultValue={undefined}
                        control={method.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="lat"
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="p-editPageTemplate_input">
                      <Typography.Text strong>
                        {t('pub.coordinatesLong')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="long"
                        defaultValue={undefined}
                        control={method.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="long"
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24} className="u-mt-16">
                    <div className="p-editPageTemplate_input">
                      <Typography.Text strong>
                        {t('footer.address')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="address"
                        defaultValue=""
                        control={method.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col span={24} className="u-mt-16">
                    <Controller
                      name="pathImage"
                      render={({
                        field: { value, onChange },
                      }) => (
                        <div className="u-mt-8">
                          <SelectFile
                            value={value}
                            name="pathImage"
                            titleName="titleImage"
                            altName="altImage"
                            hasOptions
                            handleSelect={(url, title, alt) => {
                              onChange(url);
                              method.setValue('titleImage', title);
                              method.setValue('altImage', alt);
                            }}
                            handleDelete={() => onChange(undefined)}
                            title={t('system.image')}
                          />
                        </div>
                      )}
                    />
                  </Col>
                </Row>
              </Card>
            </Space>
          </FormProvider>
        </Spin>
        <ModalConfirm
          isShow={!!confirm}
          handleCancel={() => setConfirm(undefined)}
          handleClose={() => setConfirm(undefined)}
        >
          {t('message.confirmSkipInfo')}
        </ModalConfirm>
      </div>
    </>
  );
};

export default EditPub;
