import { CustomerCheckInDataType, CustomersByIdParamsType, CustomersDataType } from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllCustomersService = async (params?: BaseFilterParams)
  : Promise<APIPaginationResponse<CustomersDataType[]>> => {
  const res = await axiosInstance.get('customers', { params });
  return res.data;
};

export const getCustomerByIdService = async (
  params: CustomersByIdParamsType
): Promise<CustomersDataType> => {
  const res = await axiosInstance.get(`customers/${params.id}`);
  return res.data.data;
};

export const getCustomerCheckInByIdService = async (
  id: number,
  params: BaseFilterParams
): Promise<APIPaginationResponse<CustomerCheckInDataType[]>> => {
  const res = await axiosInstance.get(`customers/${id}/checkins`, { params });
  return res.data;
};

export const exportCustomerService = async (
  params?: BaseFilterParams
): Promise<{ link: string }> => {
  const res = await axiosInstance.post('customers/prize-exports', { params });
  return res.data.data;
};
