import { EyeOutlined } from '@ant-design/icons';
import {
  Button,
  Space, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterValueProps,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useNavigateParams from 'common/hooks/useNavigateParams';
import { exportCustomerService, getAllCustomersService } from 'common/services/extends/customer';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';

export type CustomerDataTypes = {
  id: number;
  name: string;
  phone: string,
  dob: string,
  createdAt: string,
  updatedAt: string,
};

const CustomerManagement: React.FC<ActiveRoles> = ({
  roleIndex, roleCreate, roleDelete, roleUpdate
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigateParams = useNavigateParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Selectors */
  const {
    defaultPageSize
  } = useAppSelector((state) => state.system);
  const rolesUser = useAppSelector((state) => state.auth.roles);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const keyDependencies = ['getAllCustomerService', keyword, currentPage, currentView, selectedFilterList];

  /* Queries */
  const { isLoading, data: customerDataList } = useQuery(
    keyDependencies,
    () => getAllCustomersService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    { enabled: !!currentPage }
  );

  const { mutate: exportCustomerMutate, isLoading: exportLoading } = useMutation(
    'export-customer',
    async () => exportCustomerService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    {
      onSuccess: (res) => {
        if (res?.link) {
          message.success(t('message.exportSuccess'));
          window.open(res.link, '_blank');
        }
      },
      onError: () => {
        message.error(t('message.exportError'));
      }
    }
  );

  /* Variables */
  const columns: ColumnsType<CustomerDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: CustomerDataTypes, b: CustomerDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: CustomerDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: CustomerDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.name}
        </Typography.Text>
      ),
    },
    {
      title: t('system.phone'),
      dataIndex: 'phone',
      key: 'phone',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: CustomerDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.phone}
        </Typography.Text>
      ),
    },
    {
      title: t('system.dob'),
      dataIndex: 'dob',
      key: 'dob',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: CustomerDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.dob}
        </Typography.Text>
      ),
    },
    // --- Tạo lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 250,
      sorter: {
        compare: (a: CustomerDataTypes, b: CustomerDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: CustomerDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 250,
      key: 'updatedAt',
      sorter: {
        compare: (a: CustomerDataTypes, b: CustomerDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: CustomerDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: CustomerDataTypes) => (
        <Space>
          <Button
            disabled={!roleIndex}
            icon={<EyeOutlined />}
            onClick={() => navigateParams(`${ROUTE_PATHS.CUSTOMER_DETAIL}`, `id=${_data.id}`)}
          />
        </Space>
      ),
    },
  ];

  const customerData: CustomerDataTypes[] = useMemo(() => (customerDataList?.data?.map(
    (val) => ({
      id: val.customerData.id,
      name: val.customerData.name,
      phone: val.customerData.phone,
      dob: val.customerData.dob,
      createdAt: val.customerData?.createdAt || '',
      updatedAt: val.customerData?.updatedAt || '',
    })
  ) || []), [customerDataList]);

  /* Functions */
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.customer')}
        rightHeader={getPermission(rolesUser, roles.CUSTOMER_EXPORT) ? (
          <Space>
            <Button
              type="primary"
              disabled={exportLoading}
              loading={exportLoading}
              onClick={() => exportCustomerMutate()}
            >
              {t('system.export')}
            </Button>
          </Space>
        ) : null}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading}
          handleSearch={setKeyword}
          noCheckbox
          roles={{
            roleCreate,
            roleDelete,
            roleUpdate
          }}
          tableProps={{
            initShowColumns: ['id', 'name', 'phone', 'dob', 'createdAt', 'updatedAt', 'action'],
            columns,
            pageData: customerData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: customerDataList?.meta.total || 0,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
          }}
        />
      </div>
    </>
  );
};

export default CustomerManagement;
