import { EditOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Modal,
  Select,
  Space, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterValueProps,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import {
  getAllProvincesService, updateProvinceByIdService
} from 'common/services/extends/Provinces';
import { formatDateTime } from 'common/utils/functions';
import { updateProvinceSchema } from 'common/utils/schemas';

export type ProvincesDataTypes = {
  id: number;
  name: string;
  code: string,
  region: string | null,
  createdAt: string,
  updatedAt: string,
};

type RegionParams = {
  region: string | null;
};

type UpdateProvincePrams = {
  id: number;
  params: RegionParams
};

const ProvincesManagement: React.FC<ActiveRoles> = ({
  roleCreate, roleDelete, roleUpdate
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  const [editModal, setEditModal] = useState<{
    open: boolean;
    data?: ProvincesDataTypes;
  }>({
    open: false,
    data: undefined,
  });

  const method = useForm<RegionParams>({
    mode: 'onChange',
    resolver: yupResolver(updateProvinceSchema),
    defaultValues: {
      region: undefined,
    },
  });

  /* Selectors */
  const {
    defaultPageSize
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const keyDependencies = ['getAllProvincesService', keyword, currentPage, currentView, selectedFilterList];

  /* Queries */
  const { isLoading, data: provincesDataList } = useQuery(
    keyDependencies,
    () => getAllProvincesService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    { enabled: !!currentPage }
  );

  const { mutate: updateMutation, isLoading: isLoadingUpdate } = useMutation(
    'editProvincesService',
    (paramsUpdate:
      UpdateProvincePrams) => updateProvinceByIdService(paramsUpdate.id, paramsUpdate.params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        setEditModal({ open: false, data: undefined });
        queryClient.invalidateQueries(keyDependencies);
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const convertRegion = (region: string | null) => {
    switch (region) {
      case 'northern':
        return t('provinces.northern');
      case 'central':
        return t('provinces.central');
      case 'southern':
        return t('provinces.southern');
      default:
        return '';
    }
  };

  const pulldownRegion = useMemo(() => [
    {
      label: t('provinces.northern'),
      value: 'northern',
    },
    {
      label: t('provinces.central'),
      value: 'central',
    },
    {
      label: t('provinces.southern'),
      value: 'southern',
    },
  ], [t]);

  /* Variables */
  const columns: ColumnsType<ProvincesDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: ProvincesDataTypes, b: ProvincesDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ProvincesDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ProvincesDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.name}
        </Typography.Text>
      ),
    },
    {
      title: t('provinces.code'),
      dataIndex: 'code',
      key: 'code',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ProvincesDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.code}
        </Typography.Text>
      ),
    },
    {
      title: t('provinces.region'),
      dataIndex: 'region',
      key: 'region',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ProvincesDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {convertRegion(data.region)}
        </Typography.Text>
      ),
    },
    // --- Tạo lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 250,
      sorter: {
        compare: (a: ProvincesDataTypes, b: ProvincesDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ProvincesDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 250,
      key: 'updatedAt',
      sorter: {
        compare: (a: ProvincesDataTypes, b: ProvincesDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ProvincesDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: ProvincesDataTypes) => (
        <Space>
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => setEditModal({ open: true, data: _data })}
          />
        </Space>
      ),
    },
  ];

  const provincesData: ProvincesDataTypes[] = useMemo(() => (provincesDataList?.data?.map(
    (val) => ({
      id: val.provinceData.id,
      name: val.provinceData.name,
      code: val.provinceData.code,
      region: val.provinceData.region,
      createdAt: val.provinceData?.createdAt || '',
      updatedAt: val.provinceData?.updatedAt || '',
    })
  ) || []), [provincesDataList]);

  /* Functions */
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.provinces')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading}
          handleSearch={setKeyword}
          noCheckbox
          roles={{ roleCreate, roleDelete, roleUpdate }}
          tableProps={{
            initShowColumns: ['id', 'name', 'code', 'region', 'createdAt', 'updatedAt', 'action'],
            columns,
            pageData: provincesData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: provincesDataList?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
          }}
        />
      </div>
      <Modal
        title={<Typography.Title level={3}>{t('provinces.edit')}</Typography.Title>}
        open={editModal.open}
        centered
        onCancel={() => setEditModal({ open: false, data: undefined })}
        okText={t('system.ok')}
        cancelText={t('system.cancel')}
        width={800}
        onOk={method.handleSubmit((data) => updateMutation({
          id: editModal.data?.id || 0, params: data
        }))}
        confirmLoading={isLoadingUpdate}
      >
        <FormProvider<RegionParams> {...method}>
          <form noValidate>
            <div className="t-redirectManagement_createModal_form">
              <Controller
                name="region"
                render={({
                  field,
                  fieldState: { error },
                }) => (
                  <div className="t-redirectManagement_createModal_input">
                    <Typography.Text strong>
                      {t('provinces.region')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Select
                      className="u-mt-8"
                      size="large"
                      placeholder="---"
                      style={{ width: '100%' }}
                      onChange={field.onChange}
                      value={field.value}
                      status={error?.message ? 'error' : undefined}
                    >
                      {
                        pulldownRegion.map((val, idx) => (
                          <Select.Option value={val.value} key={`option-${idx.toString()}`}>
                            {val.label}
                          </Select.Option>
                        ))
                      }
                    </Select>
                    {error && (
                      <span className="a-input_errorMessage">
                        {error.message}
                      </span>
                    )}
                  </div>
                )}
              />
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default ProvincesManagement;
