import {
  CreateUpdatePubParamsType,
  PubByIdParamsType,
  PubDataType
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllPubService = async (params?: BaseFilterParams)
  : Promise<APIPaginationResponse<PubDataType[]>> => {
  const res = await axiosInstance.get('pubs', { params });
  return res.data;
};

export const deletePubService = async (params: { ids: number[] }) => {
  const res = await axiosInstance.delete('pubs', { data: params });
  return res.data;
};

export const getPubByIdService = async (
  params: PubByIdParamsType
): Promise<PubDataType> => {
  const res = await axiosInstance.get(`pubs/${params.id}`);
  return res.data.data;
};

export const updatePubByIdService = async (
  id: number,
  params: CreateUpdatePubParamsType,
) => {
  const res = await axiosInstance.put(`pubs/${id}`, params);
  return res.data.data;
};

export const createPubService = async (
  params: CreateUpdatePubParamsType
) => {
  const res = await axiosInstance.post('pubs', params);
  return res.data.data;
};
