import {
  ProvincesDataType,
  UpdateProvincesParamsType,
  ProvincesByIdParamsType
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllProvincesService = async (params?: BaseFilterParams)
  : Promise<APIPaginationResponse<ProvincesDataType[]>> => {
  const res = await axiosInstance.get('provinces', { params });
  return res.data;
};

export const getProvinceByIdService = async (
  params: ProvincesByIdParamsType
): Promise<ProvincesDataType> => {
  const res = await axiosInstance.get(`provinces/${params.id}`);
  return res.data.data;
};

export const updateProvinceByIdService = async (
  id: number,
  params: UpdateProvincesParamsType,
) => {
  const res = await axiosInstance.put(`provinces/${id}`, params);
  return res.data.data;
};
