import {
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import PageTable from 'common/components/PageTable';
import {
  FilterValueProps,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { getCustomerCheckInByIdService } from 'common/services/extends/customer';
import { formatDateTime } from 'common/utils/functions';

export type CustomerCheckInDataTypes = {
  id: number;
  hipoint: number,
  deviceId: string,
  namePub: string;
  lat: number;
  long: number;
  nameProvince: string,
  codeProvince: string,
  regionProvince: string,
  createdAt: string,
  updatedAt: string,
};

const CustomerCheckInManagement: React.FC<ActiveRoles> = ({
  roleCreate, roleDelete, roleUpdate
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    if (searchParams.has('id')) delete paramsObj.id;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');
  const idParams = searchParams.get('id');

  /* Selectors */
  const {
    defaultPageSize
  } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const keyDependencies = ['getAllCustomersCheckInService', keyword, currentPage, currentView];

  /* Queries */
  const { isLoading, data: customerCheckInDataList } = useQuery(
    keyDependencies,
    () => {
      if (idParams) {
        return getCustomerCheckInByIdService(Number(idParams), {
          keyword, page: currentPage, limit: currentView, ...returnParamsFilter
        });
      }
      return undefined;
    },
    { enabled: !!currentPage }
  );

  /* Variables */
  const columns: ColumnsType<CustomerCheckInDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: CustomerCheckInDataTypes, b: CustomerCheckInDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: CustomerCheckInDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.hipoint'),
      dataIndex: 'hipoint',
      key: 'hipoint',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: CustomerCheckInDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.hipoint}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.deviceId'),
      dataIndex: 'deviceId',
      key: 'deviceId',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: CustomerCheckInDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.deviceId}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.namePub'),
      dataIndex: 'namePub',
      key: 'namePub',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: CustomerCheckInDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.namePub}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.lat'),
      dataIndex: 'lat',
      key: 'lat',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: CustomerCheckInDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.lat}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.long'),
      dataIndex: 'long',
      key: 'long',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: CustomerCheckInDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.long}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.nameProvince'),
      dataIndex: 'nameProvince',
      key: 'nameProvince',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: CustomerCheckInDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.nameProvince}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.codeProvince'),
      dataIndex: 'codeProvince',
      key: 'codeProvince',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: CustomerCheckInDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.codeProvince}
        </Typography.Text>
      ),
    },
    {
      title: t('customer.regionProvince'),
      dataIndex: 'regionProvince',
      key: 'regionProvince',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: CustomerCheckInDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.regionProvince}
        </Typography.Text>
      ),
    },
    // --- Tạo lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 250,
      sorter: {
        compare: (a: CustomerCheckInDataTypes, b: CustomerCheckInDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: CustomerCheckInDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 250,
      key: 'updatedAt',
      sorter: {
        compare: (a: CustomerCheckInDataTypes, b: CustomerCheckInDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: CustomerCheckInDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    }
  ];

  const customerData: CustomerCheckInDataTypes[] = useMemo(() => (
    customerCheckInDataList?.data?.map(
      (val) => ({
        id: val.customerCheckinData.id,
        hipoint: val.customerCheckinData.hipoint,
        deviceId: val.customerCheckinData.deviceId,
        namePub: val.pub.name,
        lat: val.pub.coordinates.lat,
        long: val.pub.coordinates.long,
        nameProvince: val.province.name,
        codeProvince: val.province.code,
        regionProvince: val.province.region,
        createdAt: val.customerCheckinData?.createdAt || '',
        updatedAt: val.customerCheckinData?.updatedAt || '',
      })
    ) || []), [customerCheckInDataList]);

  /* Functions */
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam },
      ...idParams && { id: idParams }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Render */
  return (
    <>
      <div>
        <Typography.Title level={4}>
          {t('customer.checkInList')}
        </Typography.Title>
      </div>
      <div>
        <PageTable
          isLoading={isLoading}
          handleSearch={setKeyword}
          noCheckbox
          roles={{
            roleCreate,
            roleDelete,
            roleUpdate
          }}
          tableProps={{
            initShowColumns: ['id', 'hipoint', 'deviceId', 'namePub', 'lat', 'long', 'nameProvince', 'codeProvince', 'regionProvince', 'createdAt', 'updatedAt'],
            columns,
            pageData: customerData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: customerCheckInDataList?.meta.total || 0,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
          }}
        />
      </div>
    </>
  );
};

export default CustomerCheckInManagement;
